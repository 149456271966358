import React, { useCallback, useMemo, useState } from 'react'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import { isColumnPrintable } from '/src/models/concerns/grid'
import ConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file_modal'

export default function useConfigurePrintPDF(gridColumns, gridDataSource, model) {
  const [isDialogOpened, setIsDialogOpened] = useState(false)

  const hasColumns = gridColumns && gridColumns.length > 0
  const hasItems = gridDataSource && gridDataSource.total > 0

  const isPrintable = hasColumns && hasItems

  const openPrintGridCofiguration = useCallback(() => setIsDialogOpened(true), [])

  const printableColumns = useMemo(() => gridColumns && gridColumns.filter(isColumnPrintable), [gridColumns])

  const popupComponent = isDialogOpened && (
    <ConfigurePrintGridFile onClose={() => setIsDialogOpened(false)} columns={printableColumns} model={model} />
  )

  const printList = () => {
    dispatch({
      type: BusEvents.PRINT_LIST,
      payload: { model, gridData: gridDataSource.data }
    })
  }

  return [openPrintGridCofiguration, popupComponent, isPrintable, printList]
}
